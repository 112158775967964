import {AjaxSync} from "./shared/ajax_utilities";

$( () => {
  CreateMessageQueueStatusPage();
});

function CreateMessageQueueStatusPage()
{
  AjaxSync({ url: `${app.CACHE.URL_ADMIN}queue_status/ajax`, method: 'GET' }, { done: LoadMessageQueueStatuses });
}

function LoadMessageQueueStatuses(data)
{
  BuildQueueContainers(Object.keys(data.data))
  for (const queue in data.data) {
    const $queueContainer = $('div.' + queue)
    const statuses = data.data[queue].status
    $queueContainer.find('h3').text(kebabToTitleCase(queue))
    AddQueueStatistics($queueContainer, statuses)
    const jobDetails = data.data[queue].next
    if (jobDetails.length === undefined) {
      AddJobDetails($queueContainer, jobDetails)
    } else {
      RenderEmptyJobDetails($queueContainer);
    }

    AttachButtonHandlers(queue)
  }

  window.setTimeout(CreateMessageQueueStatusPage, 15000)
}

function BuildQueueContainers(queueNames)
{
  const $container = $('div#box-container')
  const existing = []
  $container.find('div.queue').each(function () {existing.push(this.id)})

  const toAdd = array_diff(queueNames, existing)
  const toRemove = array_diff(existing, queueNames)

  if ($container.find('div#no-queues').length > 0 && toAdd.length > 0) {
    $container.find('div#no-queues').remove()
  }

  for (const queue of toAdd) {
    const $newBox = $('div#template').clone()
    $newBox.attr('id', queue)
           .addClass(queue)
           .css('display', '')

    $container.append($newBox)
  }

  for (const queue of toRemove) {
    const queueContainer = $container.find('div.' + queue)
    console.log(`Removing ${queue}: found ${queueContainer.length} elements`)
    queueContainer.remove()
  }

  existing.length = 0
  $container.find('div.queue').each(function () {existing.push(this.id)})
  existing.sort()
  for (const queue of existing) {
    const $div = $container.find('div.' + queue);
    $div.remove()
    $container.append($div);
  }

  if ($container.find('div.queue').length === 0 && $container.find('div#no-queues').length === 0) {
    const $noQueues = $('<div id="no-queues"><h2>No Queues available</h2></div>')
    $container.append($noQueues)
  }
}

function AddQueueStatistics($queueContainer, statuses)
{
  const $statusList = $queueContainer.find('tbody.tube-status');

  for (const status in statuses) {
    if (status === 'name')
      continue

    let $row = $statusList.find(`tr#${status}`)
    if ($row.length === 0) {
      $row = $(`<tr id="${status}"><td class="name"></td><td class="value"></td></tr>`)
      $statusList.append($row)
    }

    const statusLabel = kebabToTitleCase(status)
    $row.find('td.name').text(statusLabel);
    $row.find('td.value').text(statuses[status])
  }
}

function array_diff(array1, array2) {
  return array1.filter(function(elm) {
    return array2.indexOf(elm) === -1;
  })
}

function AddJobDetails ($queueContainer, jobDetails) {
  const $row = $queueContainer.find('tbody.next-message>tr')

  $row.find('td.id').text(jobDetails.id)
  $row.find('td.body').text(jobDetails.data)
}

function RenderEmptyJobDetails ($queueContainer) {
  const $row = $queueContainer.find('tbody.next-message>tr')

  $row.find('td.id').text('No messages')
  $row.find('td.body').text('')
}

function AttachButtonHandlers(queue) {
  const $flushButton = $(`div.${queue} button#flush`)
  const $restartButton = $(`div.${queue} button#restart`)

  $flushButton.off('click')
  $restartButton.off('click')

  $flushButton.on('click', (e) => {
    const url = app.CACHE.URL_ADMIN + `queue_status/flush/${queue}`
    $(e.currentTarget).attr('disabled', true)
    $.post(url).then(() => $(e.currentTarget).attr('disabled', false))
  })

  $restartButton.on('click', (e) => {
    const url = app.CACHE.URL_ADMIN + `queue_status/restart/${queue}`
    $(e.currentTarget).attr('disabled', true)
    $.post(url).then(() => $(e.currentTarget).attr('disabled', false))
  })
}

function kebabToTitleCase (label) {
  return label
    .replace(
      /^([a-z0-9])/,
      (s, match) => ' ' + match.toUpperCase()
    )
    .replace(
      /-([a-z0-9])/g,
      (s, match) => ' ' + match.toUpperCase()
    )
}
